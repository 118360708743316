<!-- Presentation Name -->
<stretchy-input class="presentation-name" [(ngModel)]="templateEditorFactory.presentation.name" ngDefaultControl></stretchy-input>
<div>
  <button tabindex="0" id="deleteButton" aria-label="Delete presentation" [disabled]="!templateEditorFactory.presentation.id" (click)="confirmDelete()" class="btn-icon ml-2">
    <streamline-icon class="streamline-component-icon" ariaLabel="Delete presentation" name="trash" width="16" height="16"></streamline-icon>
  </button>
  <div class="btn-group" dropdown>
    <button tabindex="0" id="moreSettingsButton" aria-label="More options" [matMenuTriggerFor]="moreMenu" class="btn btn-default-icon dropdown-toggle ml-2">
      <streamline-icon ariaLabel="More options" name="ellipsis" width="20" height="20"></streamline-icon>
    </button>
    <mat-menu [hasBackdrop]="false" #moreMenu="matMenu">
      <button mat-menu-item id="changeTemplateButton" uiSref="apps.editor.add">New Presentation</button>
      <button mat-menu-item
              id="copyPresentationButton"
              [attr.disabled]="!templateEditorFactory.presentation.id"
              (click)="templateEditorFactory.copyPresentation()">Duplicate</button>
      <button mat-menu-item
              *requireRole="'!ap'"
              id="sharePresentationButton"
              [attr.disabled]="!templateEditorFactory.presentation.id"
              (click)="openSettings()">Save Presentation As Template</button>
    </mat-menu>
  </div>
</div>
<div class="flex-row ml-auto hidden-xs">
  <div class="auto-save pr-4">
    <last-modified [changeDate]="templateEditorFactory.presentation.changeDate" [changedBy]="templateEditorFactory.presentation.changedBy"></last-modified>
    •
    @if (templateEditorFactory.hasContentEditorRole()) {
      <span id="autoSavingDesktop">
        <span [shown]="!templateEditorFactory.savingPresentation && templateEditorFactory.isUnsaved()">
          Unsaved changes
        </span>
        <span [shown]="templateEditorFactory.savingPresentation">
          Saving changes...
        </span>
        <span [shown]="!templateEditorFactory.savingPresentation && !templateEditorFactory.isUnsaved()">
          All changes saved <streamline-icon aria-hidden="true" name="checkmark" width="12" height="12"></streamline-icon>
        </span>
      </span>
    }
    @else {
      <span class="text-danger">
        You don’t have permission to edit
      </span>
    }
  </div>
  <button tabindex="0" id="publishButtonDesktop" *requireRole="'cp ap'" class="btn btn-primary btn-fixed-width"
    [disabled]="templateEditorFactory.isPublishDisabled()"
    (click)="templateEditorFactory.publish()">
    Publish
  </button>
</div>

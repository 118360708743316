<mat-form-field appearance="outline" class="url-field">
  @if(label) {
    <mat-label>{{label}}</mat-label>
  }
  <input
    matInput
    name="urlInput"
    id="{{inputId}}"
    (blur)="onBlur()"
    [formControl]="inputControl"
    [placeholder]="placeholder ?? 'https://'" />
  @if(acceptedFileTypes) {
    <button
      matSuffix
      mat-icon-button
      [disableRipple]="false"
      type="button"
      id="storage-selector"
      (click)="openFileSelector()"
      aria-label="Select from storage">
      <mat-icon class="small-icon storage-icon" svgIcon="rise-storage" />
    </button>
  }
  @if(control?.errors?.required) {
    <mat-error>This field is required.</mat-error>
  } @else if(control?.errors?.url) {
    <mat-error>Please provide a valid URL.</mat-error>
  } @else if (control?.errors?.httpUrl) {
    <mat-error>We can’t show this content because the URL is insecure. Make sure the URL begins with "HTTPS". If you need assistance, please email <a target="_blank" href="mailto:support@risevision.com">support&#64;risevision.com</a>.</mat-error>
  } @else if (control?.errors?.responseHeader) {
    <mat-error><ng-container *ngTemplateOutlet="responseHeaderError; context: { $implicit: control.getError('responseHeader') }"/></mat-error>
  } @else if (control?.errors?.fileType) {
    <mat-error>
      Please provide a valid file type. (
        {{control.getError('fileType').extensions}}
      )
    </mat-error>
  } @else if(control?.errors?.noPreviewUrl) {
    <mat-error>Using Preview URLs is not supported. You can place one Presentation into another using Embedded Presentations.</mat-error>
  }
</mat-form-field>

<ng-template #responseHeaderError let-errorType>
  @if (errorType === 'not-reachable') {
    The requested URL could not be reached. Please try again.
  } @else if (errorType === 'content-type') {
    Invalid content type. Please provide a webpage, image, audio or video URL.
  } @else {
    The owner of the Web Page at the URL provided does not allow the page to be embedded within an iFrame. If possible, please contact the Web Page owner to discuss (<a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/HTTP/X-Frame-Options">X-Frame-Options</a> / <a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy/frame-ancestors">Content-Security-Policy</a>).
  }
</ng-template>
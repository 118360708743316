import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';
import { GoogleService } from './google.service';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService  extends GoogleService {
  public static readonly ENDPOINT_FB_PAGES = 'meta/pages';
  public static readonly ENDPOINT_FB_SELECT_PAGE = 'meta/select-page';

  getPages(account: string) {
    const requestOptions = this._getRequestOptions();

    requestOptions.params = new HttpParams({fromObject:{
      account
    }});

    const request = new HttpRequest( 'GET',
      environment.DATA_SERVICE_URL + SocialMediaService.ENDPOINT_FB_PAGES,
      requestOptions
    );

    return lastValueFrom(this.httpClient.request(request))
      .then((response: any) => {
        if (response?.body?.pages) {
          return response.body.pages;
        } else {
          throw new Error('Invalid data');
        }
      }).catch((err) => {
        console.error('Failed to get facebook pages.', err);

        throw err;
      });
  }

  selectPage(account: string, pageId: string) {
    const requestOptions = this._getRequestOptions();

    requestOptions.params = new HttpParams({fromObject:{
      account,
      pageId
    }});

    return lastValueFrom(this.httpClient.put(environment.DATA_SERVICE_URL + SocialMediaService.ENDPOINT_FB_SELECT_PAGE,
      null,
      requestOptions
    ))
      .then((response: any) => {
        if (response.success) {
          return true;
        } else {
          throw new Error('Invalid data');
        }
      }).catch((err) => {
        console.error('Failed to select facebook page.', err);

        throw err;
      });
  }

}
